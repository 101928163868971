import React from "react"
import { MainLayout } from "../layouts"
import { SEO } from "../components"
import styled from "styled-components"
import { Link } from "gatsby"
import { COLORS } from "../constants"

const Content = styled.section`
  min-height: 100vh;
  padding: 50px 40px 50px 40px;
`

const Logo = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 0 auto 50px auto;
  display: block;
`

const Err = styled.img`
  width: 100%;
  border-radius: 40px;
  max-width: 350px;
  margin: 0 auto 20px auto;
  display: block;
  border-left: solid ${COLORS.BLUE} 2px;
  border-right: solid ${COLORS.GREEN} 2px;
  border-top: solid ${COLORS.PINK} 2px;
  border-bottom: solid ${COLORS.YELLOW} 2px;
  padding: 30px;
`

const ErrDescription = styled.p`
  color: ${COLORS.FONT};
  font-weight: 300;
  text-align: center;
  line-height: 1.3em;
  margin: 0 0 20px 0;
  font-size: calc(1.4em + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
`

const Back = styled(Link)`
  color: #125dff;
  text-decoration: none;
  font-size: 1.2em;
  text-align: center;
  display: block;
`

const NotFoundPage = () => (
  <MainLayout>
    <SEO title="404: Página não encontrada." />

    <Content>
      <Logo alt="Roboeduc" src={require("../images/logo.svg")} />
      <Err alt="404 | Página não encontrada." src={require("../images/404.svg")} />
      <ErrDescription>Página não encontrada.</ErrDescription>
      <Back to="/"> Página inicial </Back>
    </Content>
  </MainLayout>
)

export default NotFoundPage
